export class deviceDB {
	static async addDevice(db, device) {
		const tx = await db.transaction('device', 'readwrite');
		const store = await tx.objectStore('device');
		const option = await store.get('device')
		if (option === undefined) {
			await store.put(device, 'device');
		}
		await tx.done;
	}
	static async getDevice(db) {
		let res
		try {
			res = await db.get('device', 'device').then(data => data)
		} catch (e) {
			res = new Error(e)
		}
		return res
	}
}