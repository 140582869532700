import React from 'react'

import cl from './MainButton.module.css'

function MainButton(props) {
  return (
	 <button className={cl.button} {...props}>{props.children}</button>
  )
}

export default MainButton