export class WatchedListtDB {
	static async addWatchedList(db, list) {
		const tx = db.transaction('list', 'readwrite');
		const store = tx.objectStore('list');
		await store.put(list, 'array');
		await tx.done;
	}
	static async getWatchedList(db) {
		let res
		try{
			res = await db.get('list', 'array').then(data => data)
			if(res === undefined) {
				throw new Error('List if undefined')
			}
		} catch (e) {
			res = new Error(e)
		}
		return res
	}
}

