import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';

import cl from './BurgerMenu.module.css'

import MyModal from '../UI/MyModal';
import ModalLogout from './../UI/ModalLogout';

function BurgerMenu({menuClose, menuVisibility}) {
	//style
	let overlay = cl.overlay
	let container = cl.container
	if(menuVisibility) {
		overlay = `${cl.overlay} ${cl.active}`
		container = `${cl.container} ${cl.active}`
	}
	const [modalIsOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();

	const navigateTo = (path) => {
		menuClose()
		navigate(path)
	}
	
	return (
		<div className={overlay} onClick={menuClose}>
			<div className={container} onClick={e => e.stopPropagation()}>
				<ul>
					<li onClick={() => navigateTo('/library/help')}>FAQ</li>
					<li onClick={() => setIsOpen(true)}>Logout</li>
				</ul>
			</div>
			<MyModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} >
				<ModalLogout setIsOpen={setIsOpen} />
			</MyModal>
		</div>
	)
}

export default BurgerMenu