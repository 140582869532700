import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Outlet } from 'react-router-dom'


import { VideoService } from './VideoService'
import { getVideoListDB } from '../db/serviceVideoListDB'
import { getVideoDB } from '../db/serviceVideoDB'

import Spinner from '../UI/Spinner'
import OnlineIndicate from '../components/OnlineIndicate'
function Layout() {

	//* video management

	const [videoName, setVideoName] = useState({
		name: '',
		resolution: '1280',
		isLoading: false,
		callback: null,
		error: '',
		saving: null,
		downloadingName: '',
		pc: null,
		cancel: null,
		videoList: [],
		done: ''
	})

	let vidName

	const refreshVideoList = () => {
		getVideoListDB('getVideoList')
			.then((data) => {
				if (!data || data.length === 0) {
					setVideoName(old => ({
						...old,
						done: vidName
					}))
					return Promise.reject();
				}
				if (data === 'done') {
					return Promise.resolve();
				}
				if (data.message) {
					return Promise.reject(data);
				}
				const name = data[0]
				const newList = data.filter((item, index) => index !== 0 && item)
				setVideoName(old => ({
					...old,
					name: name || '',
					videoList: newList
				}))
				getVideoListDB('addVideoList', { videoList: newList })
			})
			//.then(() => {
			//	console.log(videoName.videoList);
			//})
			.catch(e => {
				if (!e) {
					vidName = ''
					return
				} else {
					setVideoName(old => ({
						...old,
						error: e.message
					}))
				}
			})
	}

	useEffect(() => {
		refreshVideoList()
	}, [])


	const getVideo = async (url) => {
		const controller = new AbortController();

		if (videoName.isLoading) {
			const newList = [vidName, ...videoName.videoList]
			setVideoName(old => ({
				...old,
				name: '',
				videoList: newList
			}))
			getVideoListDB('addVideoList', { videoList: newList })
			return
		}

		setVideoName(old => ({
			...old,
			name: '',
			isLoading: true,
			error: '',
			downloadingName: vidName,
			cancel: () => {
				controller.abort()
			}
		}))

		try {
			const data = await VideoService.getVideo(url, controller, setVideoName)
			if (data?.data) {
				await saveVideoToDB(data.data)
			}
		} catch (e) {
			if (axios.isCancel(e)) {
				return
			}
			setVideoName(old => ({
				...old,
				error: e.message
			}))
		} finally {
			setVideoName(old => ({
				...old,
				isLoading: false,
				downloadingName: ''
			}))
			refreshVideoList()
		}
	}

	if (videoName.name !== '') {
		//debugger
		let url;
		switch (videoName.name) {
			case 'module1':
				vidName = 'module1'
				url = process.env.REACT_APP_MAIN_API_URL + `/download/${vidName}/${videoName.resolution}`
				break;
			case 'module2':
				vidName = 'module2'
				url = process.env.REACT_APP_MAIN_API_URL + `/download/${vidName}/${videoName.resolution}`
				break;
			case 'module3':
				vidName = 'module3'
				url = process.env.REACT_APP_MAIN_API_URL + `/download/${vidName}/${videoName.resolution}`
				break;
			case 'module4':
				vidName = 'module4'
				url = process.env.REACT_APP_MAIN_API_URL + `/download/${vidName}/${videoName.resolution}`
				break;
			case 'module5':
				vidName = 'module5'
				url = process.env.REACT_APP_MAIN_API_URL + `/download/${vidName}/${videoName.resolution}`
				break;
			default:

				break;
		}
		getVideo(url)
	}

	const saveVideoToDB = async (blob) => {
		setVideoName(old => ({
			...old,
			saving: true
		}))
		await getVideoDB('addVideo', {
			videoName: vidName,
			blob: blob
		})
			.catch(e => {
				setVideoName(old => ({
					...old,
					error: e
				}))
			})
		await setVideoName(old => ({
			...old,
			saving: false,
			done: vidName
		}))
	}

	return (
		<>
			<div style={{ position: 'fixed', top: 0, right: 0, width: '50%', height: '60px', display: 'flex', justifyContent: 'flex-end', zIndex: 25 }}>
				{videoName.isLoading && <Spinner width={20} percent={videoName.pc} />}
				{videoName.error && <p style={{ color: 'red', margin: '20px' }}>Error</p>}
				{/*<Spinner width={20} percent={pcCompleted} />*/}
			</div>
			<OnlineIndicate />
			<Outlet context={[videoName, setVideoName]} />

		</>

	)
}

export default Layout