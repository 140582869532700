import React from 'react'

import backBlue from '../assets/modules/backBlue.svg'
import backWhite from '../assets/modules/backWhite.svg'

function BackIcon({ primary = true }) {
	return (
		<>
			<img src={primary ? backBlue : backWhite} /> Back
		</>
	)
}

export default BackIcon