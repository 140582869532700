import { createOptionsDB } from './serviceOptionsDB';
import { createVideoDB } from './serviceVideoDB';
import { createVideoListDB } from './serviceVideoListDB';
import { createWatchedVideosListDB } from './serviceWatchedListDB';

export const openMainDB = async() => {
	createVideoDB()
	createVideoListDB()
	createOptionsDB()
	createWatchedVideosListDB()
}