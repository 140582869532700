import React from 'react'

import cl from './SkipWaitingSW.module.css'

function SkipWaitingSW({handleUpdate, closeWindow}) {
  return (
	 <div className={cl.container} style={{textAlign: 'center'}}>
		<h3>Application was update!</h3>
		<div style={{ marginTop: 15}}>
			<a onClick={handleUpdate} className={cl.line}>Reload</a>
			<a onClick={closeWindow} className={cl.line}>Cancel</a>
		</div>

	 </div>
  )
}

export default SkipWaitingSW