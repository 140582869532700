import React from 'react'

import {
	Routes,
	Route
} from "react-router-dom";
import Login from '../screen/Login';
import Library from '../screen/Library';
import Module from '../screen/Module';
import Player from '../screen/Player';
import Layout from '../service/Layout';
import NotFound from '../screen/NotFound';
import FAQ from '../screen/FAQ';
import Privacy from '../screen/Privacy';
function RoutesComp() {
	
	return (

		<Routes>
			<Route path="/" element={<Login />} />
			<Route path="policy" element={<Privacy />} />
			<Route path="library" element={<Layout />}>
				<Route index element={<Library />} />
				<Route path="/library/help" element={<FAQ />} />
				<Route path="/library/:moduleId" element={<Module />} />
				<Route path="/library/:moduleId/:videoId" element={<Player />} />
				<Route path="*" element={<NotFound />} />
			</Route>
			<Route path="notfound" element={<NotFound />} />
			{/*<Route path="*" element={<Navigate replace to="/notfound" />} />*/}
		</Routes>

	)
}

export default RoutesComp