import React, {useEffect} from 'react'

import cl from './Checkbox.module.css'

function Checkbox({ checked, setChecked, label = '', id }) {
	useEffect(() => {
	}, [checked])
	
	return (
		<div className={cl.checkbox}>
			<input id={id} className={cl.checkbox__input} type="checkbox" value="1" name="c_1" checked={checked} onChange={() => setChecked(!checked)} />
			<label  htmlFor={id} className={cl.checkbox__label}>
				<span className={cl.checkbox__text}>{label}</span>
			</label>
		</div>
	)
}

export default Checkbox