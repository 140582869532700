export class osDB {
	static async addOS(db, os) {
		const tx = await db.transaction('os', 'readwrite');
		const store = await tx.objectStore('os');
		const option = await store.get('OS')
		if (option === undefined) {
			await store.put(os, 'OS');
		}
		await tx.done;
	}
	static async getOS(db) {
		let res
		try {
			res = await db.get('os', 'OS').then(data => data)
		} catch (e) {
			res = new Error(e)
		}
		return res
	}
}