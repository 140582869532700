import axios from "axios";

const token = localStorage.getItem('token');
const phone = localStorage.getItem('phone');

const config = {
	headers: {
		'Authorization': `Bearer ${token}`
	}
}

export class VideoService {
	static videoWached(module) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/wvset`, {
			'phoneNumber': phone,
			'videoID': module,
			'moduleName': `Module ${module}`
		}, config)
	}
	static getVideosWatched() {
				return axios.get(process.env.REACT_APP_MAIN_API_URL + `/getwv/${phone}`, config)
	}
	static getVideo(url, controller, setVideoOptions) {
		return axios.get(url, {
			onDownloadProgress: progressEvent => {
				let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
				setVideoOptions(old => ({
					...old,
					pc: percentCompleted,
				}))
			},
			responseType: 'blob',
			signal: controller.signal,
			...config
		})
	}
	static getVideoUrl(module) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/downloadt/${module}`, config)
	}
}