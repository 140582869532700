function handleOrientation(event) {
	const alpha = event.alpha;
	const beta = event.beta;
	const gamma = event.gamma;
}

export class PermissionService {
	static getPermission(callback) {
		if (typeof DeviceMotionEvent.requestPermission === 'function') {
			// Handle iOS 13+ devices.
			DeviceMotionEvent.requestPermission()
				.then((state) => {
					if (state === 'granted') {
						window.addEventListener('devicemotion', handleOrientation);
						callback('Granted')
						return true
					} else {
						alert('Rejected')
						callback("Rejected")
						return false
					}
				})
				.catch(e => callback(e.message));
		} else {
			// Handle regular non iOS 13+ devices.
			callback('Granted')
			window.addEventListener('devicemotion', handleOrientation);
			return true
		}
	}
	static getPersistStore() {
		if (navigator.storage && navigator.storage.persist) {
			navigator.storage.persist().then((persistent) => {
				if (persistent) {
					alert('Granted store');
				} else {
					alert('Rejected store');
				}
			});
		} else {
			alert('Not supported');
		}
	}
}