import axios from "axios";

const token = localStorage.getItem('token');
const config = {
	headers: {
		'Authorization': `Bearer ${token}`
	}
}

export class LoginService {
	static login(phone) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/user/auth`, {
			"phone": phone
		})
	}
	static async checkToken() {
		const token = await localStorage.getItem('token');
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/checker`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
	static checkSentCode(code, phone) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/acceptCode`, {
			phone,
			code
		})
	}
}