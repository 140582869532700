export class permissionDB {
	static async addPermission(db, permission) {
		const tx = await db.transaction('permission', 'readwrite');
		const store = await tx.objectStore('permission');
		const option = await store.get('permission')
		if (option !== permission) {
			await store.put(permission, 'permission');
		}
		await tx.done;
	}
	static async getPermission(db) {
		let res
		try {
			res = await db.get('permission', 'permission').then(data => data)
		} catch (e) {
			res = new Error(e)
		}
		return res
	}
}