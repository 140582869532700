import React from 'react'
import classNames from 'classnames'

import './MyModal.css'

function MyModal({ modalIsOpen, setIsOpen, children, propagination = true }) {

	const overlay = classNames('overlay', {active: modalIsOpen})



	return (
		<div className={overlay} onClick={() => setIsOpen(false)}>
			<div className="modal" onClick={(e) => propagination && e.stopPropagation()}>
				{children}
			</div>
		</div>
	)
}

export default MyModal