import React from 'react'
import { useNavigate } from 'react-router-dom';

import MainButton from '../UI/MainButton'
import cl from '../styles/NotFound.module.css'

function NotFound() {
	const navigate = useNavigate()
	return (
		<div className={cl.screen}>
			<p>Oops!</p>
			<div className={cl.number}>404</div>
			<p>The page is not found</p>
			<MainButton onClick={() => navigate('/library', {replace: true})}>Back to main page</MainButton>
		</div>
	)
}

export default NotFound