import React from 'react'
import SpinnerWithoutPC from '../UI/SpinnerWithoutPc'

import cl from './LoadingOverlay.module.css'

function LoadingOverlay() {
  return (
	 <div className={cl.screen}>
		 <SpinnerWithoutPC width={120} />
	 </div>
  )
}

export default LoadingOverlay