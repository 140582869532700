import React, { useEffect, useState } from 'react'
import videojs from 'video.js';
import 'videojs-vr';
import "video.js/dist/video-js.css";
import './VideoPlayer.css'
import '@silvermine/videojs-quality-selector/dist/css/quality-selector.css'

import { VideoService } from '../service/VideoService';
import { useFetching } from '../hooks/useFetching';
import LoadingOverlay from '../components/LoadingOverlay';
import { getOptionsDB } from '../db/serviceOptionsDB';
import { getWatchedVideosListDB } from '../db/serviceWatchedListDB';

require('@silvermine/videojs-quality-selector')(videojs);

function VideoJS({ options, setVideoWatched, onReady, vrReady, OS }) {
	const videoRef = React.useRef(null);
	const playerRef = React.useRef(null);
	const Button = videojs.getComponent('Button');
	let device
	const getDevice = async () => {
		device = await getOptionsDB('getDevice')
	}
	React.useEffect(() => {
		getDevice()
		// make sure Video.js player is only initialized once
		if (!playerRef.current) {
			const videoElement = videoRef.current;
			if (!videoElement) return;
			const player = playerRef.current = videojs(videoElement, options, () => {
				player.mediainfo = player.mediainfo || {};
				player.mediainfo.projection = '360';

				const MyButton = videojs.extend(Button, {
					constructor: function () {
						Button.apply(this, arguments);
						this.addClass('targetButton');
					},
					handleClick: function () {
						player.vr().camera.position.x = 0;
						player.vr().camera.position.y = 0;
						player.vr().camera.position.z = 0;
					}
				});
				videojs.registerComponent('MyButton', MyButton);
				player.controlBar.addChild('QualitySelector');
				player.getChild('controlBar').addChild('myButton', {});

				player.vr({
					motionControls: true,
					projection: 'AUTO',
					forceCardboard: false
				});

				!vrReady && player.enterFullWindow()

				//const modal = player.createModal('This is a modal!');
				//modal.on('modalclose', function () {
				//	onReady(true)
				//});

				player.vr().on('initialized', () => {
					if (vrReady) {
						setTimeout(() => {
							document.querySelector('.vjs-button-vr').click();
						}, 1000);
					}
				});

				let videoWatched
				player.ready(() => {
					onReady(true)
				});

				player.on('playing', function (e) {
					videoWatched = +player.duration() * 0.9
					player.vr().camera.position.x = 0;
					player.vr().camera.position.y = 0;
					player.vr().camera.position.z = 0;
				});

				player.on('timeupdate', function (e) {
					const curTime = +player.currentTime().toFixed(0)
					if (curTime > videoWatched) {
						setVideoWatched(true)

						player.off('timeupdate')
					}

				});
			});

		}
	}, [onReady, options]);

	// Dispose the Video.js player when the functional component unmounts
	React.useEffect(() => {

		return () => {
			if (playerRef && playerRef.current) {
				playerRef.current.exitFullWindow();
				playerRef.current.dispose();
				playerRef.current = null;
			}

		};
	}, []);

	return (
		<div className="video-container ion-margin-vertical" data-vjs-player>
			{OS === 'IOS'
				? <video playsInline autoPlay crossOrigin="anonymous" ref={videoRef} className="video-js vjs-big-play-centered" />
				: <video crossOrigin="anonymous" autoPlay ref={videoRef} className="video-js vjs-big-play-centered" />
			}

		</div>
	);
}

const VideoPlayer = ({ src, onReady, vrReady, moduleId, ...props }) => {

	const [os, setOs] = useState(null)
	const [videoWatched, setVideoWatched] = useState(false)
	// check OS 
	const getOs = async () => {
		const os = await getOptionsDB('getOS')
		if (os === 'IOS') {
			setOs('IOS')
		} else {
			setOs('Any')
		}
	}

	useEffect(() => {
		getOs()
	}, [])


	const [fetchWatchedVideosList, listIsLoading, listError] = useFetching(async () => {
		const data = await VideoService.getVideosWatched()
		if (data) {
			const videoIds = data.data.videos.map(item => item.videoID)
			getWatchedVideosListDB('addWatchedList', { videoList: videoIds })
		}
	})

	const addVidWatched = async () => {
		await VideoService.videoWached(moduleId)
		await fetchWatchedVideosList()
	}

	useEffect(() => {
		if (videoWatched) {
			addVidWatched()
		}
	}, [videoWatched, moduleId])

	let type = "video/mp4";

	let sources = [{
		src: `${src}/1920.mp4/ios`,
		type: 'video/mp4',
		label: '1920P',
		//selected: true,
	},
	{
		src: `${src}/1280.mp4/ios`,
		type: 'video/mp4',
		label: '1280P',
		selected: true,
	},
	{
		src: `${src}/720.mp4/ios`,
		type: 'video/mp4',
		label: '720P',
	},
	]

	if (props.blob) {
		sources = [{ src, type }]
	}

	let videoJsOptions = { // lookup the options in the docs for more options
		audioPosterMode: true,
		autoplay: true,
		controls: true,
		responsive: true,
		fluid: true,
		sources: sources
	};

	if (!os) {
		return <LoadingOverlay />
	}
	return (<VideoJS options={videoJsOptions} setVideoWatched={setVideoWatched} onReady={onReady} vrReady={vrReady} OS={os} />
	);
};

export default VideoPlayer