import React from 'react'
import spinner from '../assets/spinner.svg'

function Spinner({ width = 50, absolute = false, percent = 0, ...props }) {
	let style = { display: 'flex', alignItems: 'center'}
	if (absolute) {
		style = { position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, background: 'rgba(255, 255, 255, 0.7)', zIndex: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }
	}

	return (
		<div style={style} {...props}>
			{percent !== 0 && percent !== null ? <p style={{textShadow: '1px 1px 3px #fff'}}><b>{`${percent}%`}</b></p> : <p style={{textShadow: '1px 1px 3px #fff'}}><b>0%</b></p> }
			<img src={spinner} width={width} height={width} />
			
		</div>
	)
}

export default Spinner