import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import VideoPlayer from '../libs/VideoPlayer';

import TurnDevice from '../UI/TurnDevice';
import BackIcon from '../UI/BackIcon';
import SpinnerWithoutPC from './../UI/SpinnerWithoutPc';

import { VideoService } from '../service/VideoService';
import { getOptionsDB } from '../db/serviceOptionsDB';
import { getVideoDB } from '../db/serviceVideoDB';
import { onlineContext } from '../context/OnlineContext';
import OfflinePage from './OfflinePage';


function Player() {
	const { online } = useContext(onlineContext)
	//const [orientation, setOrientation] = useState(null)
	const [modalIsOpen, setIsOpen] = useState(true);
	const [waitModal, setWaitModal] = useState(true)
	const [vrMode, setVrMode] = useState(null)
	const [playerReady, setPlayerReady] = useState(null)
	const [blobURL, setBlobURL] = useState(null)
	const [startVR, setStartVR] = useState(false)
	const [error, setError] = useState('')

	const [callback, setCallback] = useState(null)
	const [os, setOs] = useState(null)
	const [streamURL, setStreamURL] = useState(`${process.env.REACT_APP_MAIN_API_URL}/download/module1`)

	const location = useLocation();

	const navigate = useNavigate();
	const moduleId = location.pathname.slice(-1);

	const getVideoFromDB = async () => {
		if (moduleId) {
			let video = 'module1'
			switch (moduleId) {
				case '1':
					video = 'module1'
					setStreamURL(`${process.env.REACT_APP_MAIN_API_URL}/apple/${video}`)
					break;
				case '2':
					video = 'module2'
					setStreamURL(`${process.env.REACT_APP_MAIN_API_URL}/apple/${video}`)
					break;
				case '3':
					video = 'module3'
					setStreamURL(`${process.env.REACT_APP_MAIN_API_URL}/apple/${video}`)
					break;
				case '4':
					video = 'module4'
					setStreamURL(`${process.env.REACT_APP_MAIN_API_URL}/apple/${video}`)
					break;
				case '5':
					video = 'module5'
					setStreamURL(`${process.env.REACT_APP_MAIN_API_URL}/apple/${video}`)
					break;
				default:
					break;
			}
			//setStreamURL(`https://tflwebapp.azurewebsites.net/static/M${moduleId}-v1-r1.mp4`)

			getVideoDB('getVideo', {
				videoName: video,
			}).then(data => {
				if (data.message) {
					return Promise.reject(data);
				}
				setBlobURL(URL.createObjectURL(data))
			}).catch(e => {
				setBlobURL('Not found')
			})
		}

	}

	useEffect(() => {
		if (location.state) {
			setVrMode(true)
		}
		//setOrientation(screenOrientation)
		getVideoFromDB()
		setOs(() => getOptionsDB('getOS'))
	}, [])

	const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
		[orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
		onWindowResize = () => {
			clearTimeout(window.resizeLag)
			window.resizeLag = setTimeout(() => {
				delete window.resizeLag
				setOrientation(isLandscape() ? 'landscape' : 'portrait')
			}, 200)
		}

	useEffect(() => (
		onWindowResize(),
		window.addEventListener('resize', onWindowResize),
		() => window.removeEventListener('resize', onWindowResize)
	), [])

	let videoPlayer

	if (!blobURL) {
		return <SpinnerWithoutPC style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} width={200} absolute />
	} else if (blobURL !== 'Not found') {
		videoPlayer = vrMode
			? <VideoPlayer moduleId={moduleId} src={blobURL} blob onReady={setPlayerReady} vrReady={true} />
			: <VideoPlayer moduleId={moduleId} src={blobURL} blob onReady={setPlayerReady} vrReady={false} />
	} else if (!online) {
		return <OfflinePage />
	} else {
		videoPlayer = vrMode
			? <VideoPlayer moduleId={moduleId} src={streamURL} onReady={setPlayerReady} vrReady={true} />
			: <VideoPlayer moduleId={moduleId} src={streamURL} onReady={setPlayerReady} vrReady={false} />
	}

	return (
		<div className="screen">
			{videoPlayer}
			{/*<VideoPlayer src={streamURL} onReady={setPlayerReady} vrReady={false} />*/}
			{!startVR && <div className='back' style={{ zIndex: 110 }} onClick={() => navigate(-1)}>
				<BackIcon />
			</div>}
			{/*{vrMode &&
				<MyModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} propagination={false}>
					<ModalTimeContainer setIsOpen={setIsOpen} />
				</MyModal>}*/}
		</div>
	)

}

export default Player