import React from 'react'
import spinner from '../assets/spinner.svg'

function SpinnerWithoutPC({ width = 50, absolute = false, ...props }) {
	let style = { display: 'flex', alignItems: 'center'}
	if (absolute) {
		style = { position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, background: 'rgba(255, 255, 255, 0.7)', zIndex: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }
	}

	return (
		<div style={style} {...props}>
			<img src={spinner} width={width} height={width} />
			
		</div>
	)
}

export default SpinnerWithoutPC