import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

import cl from './ModuleCard.module.css'

import Spinner from '../UI/Spinner';



function ModuleCard(props) {
	const navigate = useNavigate()
	
	if(!props) {
		return <Spinner width={60} />
	}
	return (
		<div onClick={() => navigate(`/library/${props.id}`)} className={cl.card} style={{ background: `url(${props.img}) center/cover no-repeat`, color: props.color === 'primary' ? 'var(--primary-color)' : '#fff'}}>
			<div className={cl.icon}>
				<img src={props.arrow} alt="" />
			</div>
			<h2>{props.title}</h2>
			<p>{props.subtitle}</p>
			{props.watched && <div className={cl.watched}></div>}
		</div>
	)
}

export default ModuleCard