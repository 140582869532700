import React, { useState, useEffect, useContext } from 'react'
import { CSSTransition } from 'react-transition-group';

import '../styles/Animation.css'
import cl from '../styles/Library.module.css'

import BurgerMenu from '../components/BurgerMenu';
import BurgerIcon from '../UI/BurgerIcon';

import Spinner from '../UI/Spinner';

import DATA from '../data/dummy-data';

import ModuleCard from '../components/ModuleCard'
import { useFetching } from './../hooks/useFetching';
import { VideoService } from '../service/VideoService';
import { getWatchedVideosListDB } from '../db/serviceWatchedListDB';


function Library() {
	const [burgerMenuOpen, setBurgerMenuOpen] = useState(false)
	const [animate, setAnimate] = useState(false)
	const [videosList, setVideosList] = useState([])


	const [fetchWatchedVideosList, listIsLoading, listError] = useFetching(async () => {
		const data = await VideoService.getVideosWatched()
		if (data) {
			const videoIds = data.data.videos.map(item => item.videoID)
			setVideosList(videoIds)
			getWatchedVideosListDB('addWatchedList', { videoList: videoIds })
		}
	})

	const [getWatchedVideosList, videosListIsLoading, videosListError] = useFetching(async () => {
		const array = await getWatchedVideosListDB('getWatchedList')
		if (array.length > 0) {
			setVideosList(array)
		} else {
			fetchWatchedVideosList()
		}
	})

	useEffect(() => {
		getWatchedVideosList()
		setAnimate(true)
	}, [])

	const card = DATA.map(data => <ModuleCard
		title={data.title}
		img={data.img}
		subtitle={data.subt}
		arrow={data.arrow}
		color={data.textColor}
		id={data.id}
		data={data}
		watched={videosList.includes(`${data.id}`)}
		key={data.id} />)

	//onClick={() => setIsOpen(true)}

	return (
		<div className={cl.screen}>
			<header className={cl.header}>
				<div className={cl.burger} >
					<BurgerIcon onClick={() => setBurgerMenuOpen(true)} />
				</div>
				My Library
			</header>
			
			<CSSTransition
				in={animate}
				timeout={2000}
				classNames="libCards"
				unmountOnExit
			>
				<div className={cl.container}>
				
					{card ? card : <Spinner />}
				</div>
			</CSSTransition>
			<BurgerMenu menuClose={() => setBurgerMenuOpen(false)} menuVisibility={burgerMenuOpen} />


		</div>
	)
}

export default Library