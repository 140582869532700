import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import cl from './ModalContainer.module.css'

function ModalLogout({setIsOpen}) {
	const navigate = useNavigate()
	const logout = async () => {
		await localStorage.removeItem('token')
		await localStorage.removeItem('phone')
		setIsOpen(false)
		navigate('/')
		window.location.reload();
	}
  return (
		<div className={cl.container}>
			<h3>Are you sure want to sign out?</h3>
			<div className={cl.line} onClick={logout} >Yes</div>
			<div className={cl.line} onClick={()=> setIsOpen(false)}>No</div>
		</div>
	
  )
}

export default ModalLogout