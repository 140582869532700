import React from 'react'

import cl from './BurgerIcon.module.css'

function BurgerIcon({onClick}) {
  return (
	 <ul className={cl.icon} onClick={onClick}>
		 <li></li>
		 <li></li>
		 <li></li>
	 </ul>
  )
}

export default BurgerIcon