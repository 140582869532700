import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import TimeOverlay from '../components/TimeOverlay'

import cl from './ModalContainer.module.css'

function ModalContainer({ firstAction, secondAction, title, firstActionTitle, secondActionTitle }) {
	const [secondModal, setSecondModal] = useState(false)
	const VRmode = (e) => {
		e.preventDefault()
		setSecondModal(true)
	}

	return (
		<div className={cl.container}>
			<h3>{title}</h3>
			<Link className={cl.line} to={firstAction} >{firstActionTitle}</Link>
			<Link className={cl.line} to={secondAction} state={{mode: 'vr'}}>{secondActionTitle}</Link>
			{/*<a className={cl.line} onClick={(e)=>VRmode(e)}>{secondActionTitle}</a>*/}
			{/*{secondModal && <TimeOverlay to={secondAction} />}*/}
		</div>
	)
}

export default ModalContainer