import React, { useContext } from 'react'
import { onlineContext } from '../context/OnlineContext';

import cl from './OnlineIndicate.module.css'

import offline from '../assets/wifi_off.svg'

function OnlineIndicate() {
	const { online } = useContext(onlineContext)
	return (
		<>
			{!online
				? <div className={cl.online}>
					<img src={offline} />
				</div>
				: null}
			{/*<div className={cl.light} style={online ? { backgroundColor: 'rgb(68, 255, 47)' } : { backgroundColor: 'rgb(255, 47, 47)' }}></div>*/}
		</>
	)
}

export default OnlineIndicate