import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { onlineContext } from './context/OnlineContext.js';
import { LoginService } from './service/LoginService.js';
import { ServiceWorkerUpdateListener } from './ServiceWorkerUpdateListener.js'
import SkipWaitingSW from './components/SkipWaitingSW';

import RoutesComp from './routes/Routes.js';

import MyModal from './UI/MyModal';



function App() {
	//* manage online status
	const [online, setOnline] = useState(true) // расшарить на все приложение
	const { Provider } = onlineContext;

	const navigate = useNavigate()
	const url = window.location.href;

	const checkToken = async () => {
		//navigate('library')
		if (url.includes('/policy')) {
			return
		}
		const token = localStorage.getItem('token');
		if (token) {
			try {
				const data = await LoginService.checkToken()
				if (data) {
					navigate('library')
				}
			} catch (e) {
				if (e.message === 'Network Error') {
					setOnline(false)
					navigate('library')
				} else {
					navigate('/')
				}

			}
		} else {
			navigate('/')
		}
	}

	useEffect(() => {
		window.addEventListener('online', () => setOnline(true))
		window.addEventListener('offline', () => setOnline(false))
		return () => {
			window.removeEventListener('online', () => setOnline(true))
			window.removeEventListener('offline', () => setOnline(false))
		}
	}, [])

	useEffect(() => {
		if (online) {
			checkToken()
		}
	}, [online])


	//* SW update listener
	const [updateWaiting, setUpdateWaiting] = useState(false);
	const [registration, setRegistration] = useState(null);
	const [swListener, setSwListener] = useState({});

	useEffect(() => {

		if (process.env.NODE_ENV !== "development") {
			let listener = new ServiceWorkerUpdateListener();
			setSwListener(listener);
			listener.onupdateinstalling = (installingEvent) => {
				console.log("SW installed", installingEvent);
			};
			listener.onupdatewaiting = (waitingEvent) => {
				console.log("new update waiting", waitingEvent);
				setUpdateWaiting(true);
			};
			listener.onupdateready = (event) => {
				console.log("updateready event");
				window.location.reload();
			};
			navigator.serviceWorker.getRegistration().then((reg) => {
				listener.addRegistration(reg);
				setRegistration(reg);
			});
			
			return () => listener.removeEventListener();
		}
	}, [])

	const [modalOpen, setModalOpen] = useState(false)

	const skipWaitingUpdate = () => {
		setModalOpen(false)
		swListener.skipWaiting(registration.waiting);
	}
	useEffect(() => {
		if (updateWaiting) {
			setModalOpen(true)
		}
	}, [updateWaiting])


	return (<>
		<Provider value={{ online }}>
			<RoutesComp />
		</Provider>
		<MyModal modalIsOpen={modalOpen} setIsOpen={setModalOpen}>
			<SkipWaitingSW handleUpdate={skipWaitingUpdate} closeWindow={() => setModalOpen(false)} />
		</MyModal>
		{updateWaiting && <div onClick={() => setModalOpen(true)} style={{ zIndex: 1000, position: 'fixed', left: 0, bottom: 0, width: '100%', background: '#fff', fontWeight: 'bold', textAlign: 'center' }}>
			Update available!
		</div>}
	</>
	);
}

export default App;
