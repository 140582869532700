import React from 'react'
import { useNavigate } from 'react-router-dom';

import MainButton from '../UI/MainButton'
import cl from '../styles/NotFound.module.css'

function OfflinePage() {
	const navigate = useNavigate()
	return (
		<div className={cl.screen}>
			<p>Oops!</p>
			<p>You cannot watch this video offline. First download it.</p>
			<MainButton onClick={() => navigate('/library', {replace: true})}>Back to main page</MainButton>
		</div>
	)
}

export default OfflinePage