import React from 'react'

import cl from './ResolutionModal.module.css'

function ResolutionModal({ setResolution }) {
	const handleResolution = (res) => {
		setResolution(res)
	}
	return (
		<div className={cl.container}>
			<h3>Choice video resolution</h3>
			{/*<a className={cl.line} onClick={() => handleResolution(360)}>720 x 360</a>*/}
			<a className={cl.line} onClick={() => handleResolution(720)}>1440 x 720</a>
			<a className={cl.line} onClick={() => handleResolution(1280)}>2560 x 1280</a>
			<a className={cl.line} onClick={() => handleResolution(1920)}>3840 x 1920</a>
		</div>
	)
}

export default ResolutionModal