/* eslint-disable default-case */
import { openDB } from "idb";
import { deviceDB } from "./actionsDeviceDB";
import { osDB } from "./actionsOSDB";
import { permissionDB } from "./actionsPermissionDB";

const checkOS = () => {
	if (navigator.platform.indexOf("Mac") === 0 || navigator.platform === "iPhone") {
		return 'IOS'
	} else {
		return 'Any'
	}
}

const checkMobile = () => {
	if ("ontouchstart" in document.documentElement) {
		return 'Mobile'
	} else {
		return 'PC'
	}
}

export async function createOptionsDB() {
	// Using https://github.com/jakearchibald/idb
	const db = await openDB('options', 1, {
		upgrade(db, oldVersion, newVersion, transaction) {
			switch (oldVersion) {
				case 0:
					db.createObjectStore('os');
					db.createObjectStore('device');
					db.createObjectStore('permission');
				case 1:
					const os = checkOS()
					const device = checkMobile()

					getOptionsDB('addOS', os)
					getOptionsDB('addDevice', device)
					getOptionsDB('addPermission', { permission: false })
					break;
			}
		}
	});
}

export async function getOptionsDB(type, data = {}) {
	const db = await openDB('options', 1)
	switch (type) {
		case 'getOS':
			const resOS = await osDB.getOS(db)
			return resOS
		case 'addOS':
			const os = await checkOS()
			await osDB.addOS(db, os)
			break;
		case 'getDevice':
			const resDevice = await deviceDB.getDevice(db)
			return resDevice
		case 'addDevice':
			const device = await checkMobile()
			await deviceDB.addDevice(db, device)
			break;
		case 'getPermission':
			const resPerm = await permissionDB.getPermission(db)
			return resPerm
		case 'addPermission':
			await permissionDB.addPermission(db, data.permission)
			break;
		default:
			break;
	}
}