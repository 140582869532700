import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import cl from '../styles/Module.module.css'
import '../styles/Animation.css'
import clock from '../assets/modules/clock.svg'

import MainButton from '../UI/MainButton'
import MyModal from '../UI/MyModal';
import ModalContainer from '../UI/ModalContainer';
import Spinner from '../UI/Spinner';
import DownloadButton from '../UI/DownloadButton';
import ProgressBar from '../UI/ProgressBar';
import BackIcon from '../UI/BackIcon';

import DATA from '../data/dummy-data'
import NotFound from './NotFound';
import { PermissionService } from '../service/PermissionService';
import ResolutionModal from '../components/ResolutionModal';
import { getOptionsDB } from '../db/serviceOptionsDB';
import { getVideoDB } from '../db/serviceVideoDB';
import { onlineContext } from '../context/OnlineContext';



function Module() {
	const { online } = useContext(onlineContext)
	//animation
	const [topModule, setTopModule] = useState(false)
	const [bottomModule, setBottomModule] = useState(false)
	useEffect(() => {
		setBottomModule(true)
		setTopModule(true)
	}, [])

	const navigate = useNavigate()
	const [modalIsOpen, setIsOpen] = useState(false);
	const [resolutionModal, setResolutionModal] = useState(false)
	const [isDownloaded, setIsDownloaded] = useState(false)
	const [downloading, setDownloading] = useState(false)
	const [error, setError] = useState({
		error: '',
		vidName: ''
	})
	const [getting, setGetting] = useState(null)
	const [waiting, setWaiting] = useState(null)
	const [permission, setPermission] = useState(false)
	const [IOS, setIOS] = useState(false)
	const [textPermission, setTextPermission] = useState('')
	const params = useParams();
	const moduleId = params.moduleId;

	//useEffect(() => {
	//	if (+(moduleId) > 6) {
	//		return navigate('/notfound')
	//	}
	//}, [moduleId])

	const data = DATA.find(data => data.id == moduleId)
	useEffect(() => {
		if (!data) {
			return navigate('/notfound')
		}
	}, [data])

	const [videoName, setVideoName] = useOutletContext()

	const getVideoFromDB = async (vidName) => {
		setError({
			error: '',
			vidName: ''
		});
		//const db1 = await openDB('video', 3);
		setGetting(true)
		//db1.get('blobs', vidName)
		getVideoDB('getVideo', {
			videoName: vidName,
		})
			.then(data => {
				if (!data) {
					setIsDownloaded(false)
				} else if (data.message) {
					throw new Error(data)
					//} else if(moduleId === '1') {
					//	setIsDownloaded(true)
				} else {
					setIsDownloaded(true)
				}
			})
			//.then(blob => setIsDownloaded(true))
			.catch(e => {
				setError({
					error: `Get video: ${e.message}`,
					vidName: vidName
				})
				setIsDownloaded(false)
			})
			.finally(e => {
				setGetting(false)

			});
		setGetting(false)
	}

	const getPermission = async () => {
		const res = await getOptionsDB('getPermission')
		//if (res.message) {
		//	console.log(res.message);
		//}
		if (res) {
			setPermission(true)
		}
	}

	useEffect(() => {
		if (data) {
			getVideoFromDB(data.videoName)
		}
		getPermission()
		getOptionsDB('getOS').then(res => {(
			setIOS(res === 'IOS')
		)})
	}, [])


	useEffect(() => {
		if (data && videoName.done === data.videoName) {
			getVideoFromDB(data.videoName)
				.then(e => {
					setVideoName(old => ({
						...old,
						done: ''
					}))
				})
		}
	}, [data, videoName.done])

	const handleResolutionModal = () => {
		setResolutionModal(true)
	}

	const getVideo = (res) => {
		setResolutionModal(false)
		setVideoName(old => ({
			...old,
			name: data.videoName,
			resolution: res
		}))
	}
	useEffect(() => {
		if (data && data.videoName === videoName.downloadingName) {
			setDownloading(true)
			return
		}
		setDownloading(false)

	}, [data, videoName.downloadingName])


	useEffect(() => {
		if (data && videoName.videoList.includes(data.videoName)) {
			setWaiting(true)
		} else (
			setWaiting(false)
		)
	}, [data, videoName.videoList])

	const handlePlay = async () => {
		if (!permission ) {
			let permissionReq = await PermissionService.getPermission(setTextPermission)
			if (permissionReq) {
				getOptionsDB('addPermission', { permission: true })
				setPermission(true)
			}
		}
		setIsOpen(true)
	}

	let buttons = <div className={cl.buttons}>
		<MainButton onClick={handlePlay} style={{ margin: 0, fontSize: '18px', fontWeight: 600, padding: '13px 45px' }} disabled={waiting}>Play</MainButton>
		{isDownloaded
			? <DownloadButton onClick={getVideo} disabled={true} success>Downloaded</DownloadButton>
			: <DownloadButton onClick={handleResolutionModal} disabled={waiting || !online}>Download {waiting && <Spinner absolute />} </DownloadButton>
		}
	</div>

	if (downloading) {
		buttons = <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '25px' }}>
			<MainButton onClick={() => videoName.cancel()} style={{ padding: "13px 35px", margin: 0, fontSize: '18px', fontWeight: 600 }}>Cancel</MainButton>
			{/*<Downloading percent={videoName.pc} />*/}
			<ProgressBar percent={videoName.pc} />
		</div>
	}



	/*
	const [file, setFile] = useState(null)
	const setBlob = (file) => {
		console.log();
		setFile(file)
		console.log(file);
		const blob = new Blob([file], { type: 'video/mp4' });
		saveVideoToDB(blob)
	}
	*/

	if (data) {
		return (
			<div className={cl.screen}>
				<CSSTransition
					in={topModule}
					timeout={1500}
					classNames="moduleTop"
					unmountOnExit
				>
					<div className={cl.image} style={{ background: `url(${data.imgIn}) center/cover no-repeat` }}>
						<div>
							<div className={cl.arrow}>
								<img src={data.arrow} alt="" />
							</div>
							<h2 style={{ color: data.textColor === 'primary' ? 'var(--primary-color)' : '#fff' }}>{data.title}</h2>
							<p style={{ color: data.textColor === 'primary' ? 'var(--primary-color)' : '#fff' }}>{data.subt}</p>
						</div>

					</div>
				</CSSTransition>
				<CSSTransition
					in={bottomModule}
					timeout={800}
					classNames="moduleBottom"
					unmountOnExit
				>

					<div className={cl.info}>
						<div className={cl.line__duration}>
							<div className={cl.clock}>
								<img src={clock} alt="" />
							</div>
							<div className={cl.topics}>{data.topics} <p>{data.duration}</p></div>

						</div>
						<h3>{data.descrTitle}</h3>
						<p>{data.descr}</p>
						<p style={{ color: 'red', marginTop: '5px', textAlign: 'center' }}>{videoName.error}</p>
						{error.error && error.vidName === data.videoName ? <p style={{ color: 'red', marginTop: '5px', textAlign: 'center' }}>{error.error}</p> : ''}
						{videoName.saving && <div style={{ color: 'green', marginTop: '5px', display: 'flex', alignItems: 'baseline' }}>Please wait, video is saving <Spinner width={25} /> </div>}
						{getting && <div style={{ color: 'green', marginTop: '5px', display: 'flex', alignItems: 'baseline' }}>Initialization...<Spinner width={25} /> </div>}
						{buttons}
					</div>
				</CSSTransition>

				<Link className='back' style={{ color: data.textColor !== 'primary' && '#fff' }} to={'/library'}>
					<BackIcon primary={data.textColor === 'primary'} />
				</Link>

				<MyModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
					<ModalContainer firstAction={`${data.id}`} secondAction={`${data.id}`} title={'How you are going to watch the content?'} firstActionTitle={"Regular"} secondActionTitle={'Cardboard'} />
				</MyModal>
				<MyModal modalIsOpen={resolutionModal} setIsOpen={setResolutionModal}>
					<ResolutionModal setResolution={getVideo} />
				</MyModal>

			</div>
		)
	} else {
		return <NotFound />
	}
}

export default Module