import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import cl from '../styles/FAQ.module.css'
import check from '../assets/check.svg'
import cross from '../assets/cross.svg'

import BackIcon from '../UI/BackIcon';

import MainButton from '../UI/MainButton';
import { PermissionService } from '../service/PermissionService';
import { getVideoDB } from '../db/serviceVideoDB';


function FAQ() {
	const [os, setOs] = useState(null)
	const [IOS, setIOS] = useState(false)
	const [permissGyro, setPermissGyro] = useState('')

	//*check OS 
	/*
	useEffect(() => {
		checkOSDB('getOS')
			.then(data => setOs(data))
	}, [])
	useEffect(() => {
		if (os === 'IOS') {
			setIOS(true)
		}
	}, [os])
	*/
	/*
	// only for IOS
	function permission() {
		if (typeof (DeviceOrientationEvent) !== "undefined" && typeof (DeviceOrientationEvent.requestPermission) === "function") {
			DeviceOrientationEvent.requestPermission()
				.then(response => {
					if (response == "granted") {
						setPermissGyro('Granted')
					} else {
						setPermissGyro('Denied');
					}
				})
				.catch(e => alert(e.message))
		} else {
			setPermissGyro("Gyroscope not supported");
		}
	}
	*/

	function onClick() {
		PermissionService.getPermission(setPermissGyro)
	}

	//const getPersistStore = () => {
	//	PermissionService.getPersistStore()
	//}

	const [videosList, setVideosList] = useState('')
	const getVideosStore = async () => {
		setVideosList(await getVideoDB('getVideoList'))
	}

	useEffect(() => {
		getVideosStore()
	} , [])
	

	//styled
	let checkedStyle = cl.check
	let checkedIcon = check
	if (permissGyro) {
		checkedStyle = classNames(cl.check, cl.checked)
		if (permissGyro !== 'Granted') {
			checkedIcon = cross
		}
	}

	return (
		<div className='screenPage'>
			<div className='screenHeader'>
				FAQ
			</div>

			<div className={cl.line}>
				<div className={cl.text}>
					<h2>Gyro not working?</h2>
					<p>You must give permission to use the gyroscope:</p>
					{permissGyro
						? permissGyro !== 'Granted'
							? <p style={{color: '#b50000'} }>{permissGyro}</p>
							: <p>{permissGyro}</p>
						: <p className={cl.permission} onClick={onClick}>Check permission</p>
					}
				</div>
				<div className={cl.action}>
					<div className={checkedStyle}>
						<img src={checkedIcon} alt="Check" />
					</div>
				</div>
			</div>
			{/*<div className={cl.line}>
				<div className={cl.text}>
					<h2>Permission persist store</h2>
					<p className={cl.permission} onClick={getPersistStore}>Get permission</p>
					
				</div>
			</div>
			<div className={cl.line}>
				<div className={cl.text}>
					<h2>Get store list</h2>
					<p className={cl.permission} onClick={getVideosStore}>Get video list</p>
					<p>{videosList}</p>
				</div>
			</div>*/}

			{/*<MainButton onClick={onClick} >Click permission</MainButton>*/}

			<Link className='back' to={'/library'}>
				<BackIcon />
			</Link>
		</div>
	)
}

export default FAQ