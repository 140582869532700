import { openDB } from "idb";
import { VideoDB } from "./actionsVideoDB";


export async function createVideoDB() {
	const db = await openDB('video', 1, {
		upgrade(db, oldVersion, newVersion, transaction) {
			switch (oldVersion) {
				case 0:
					db.createObjectStore('blobs');
				case 1:
					break;
			}
		}
	});
}

export async function getVideoDB(type, data = {}) {
	const db = await openDB('video', 1)

	switch (type) {
		case 'addVideo':
			VideoDB.addVideo(db, data.videoName, data.blob)
			break;
		case 'getVideo':
			let res = await VideoDB.getVideo(db, data.videoName)
			//console.log(res);
			return res
		case 'getVideoList':
			let resol = await VideoDB.getAllVideos(db)
			//console.log(res);
			return resol
		default:
			break;
	}
}