import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import ReactCodeInput from 'react-code-input';
import PhoneInput from 'react-phone-input-2'

import 'react-phone-input-2/lib/style.css'
import '../styles/Animation.css'
import cl from '../styles/Login.module.css';
import img360 from '../assets/vid360.svg'
import logo from '../assets/logo.svg'

import MainButton from '../UI/MainButton'
import SpinnerWithoutPC from '../UI/SpinnerWithoutPc';
import BackIcon from '../UI/BackIcon';
import { LoginService } from '../service/LoginService';
import LoadingOverlay from '../components/LoadingOverlay';
import Checkbox from '../UI/Checkbox';
import { onlineContext } from '../context/OnlineContext';

function Login() {
	//animation: 
	const [loginTop, setLoginTop] = useState(false);
	const [loginBottom, setLoginBottom] = useState(false);
	useEffect(() => {
		setLoginTop(true)
		setLoginBottom(true)
	}, [])

	let navigate = useNavigate();

	const { online } = useContext(onlineContext)

	const [countryPhone, setCountryPhone] = useState(null)
	const [code, setCode] = useState('')
	const [error, setError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [loadingOverlay, setLoadingOverlay] = useState(false)
	const [resend, setResend] = useState(false)
	const [checkbox, setCheckbox] = useState(true)

	const checkToken = async () => {
		//*prod 
		setLoadingOverlay(true)
		const token = await localStorage.getItem('token');
		if (token) {
			try {
				const data = await LoginService.checkToken()
				if (data) {
					navigate('library')
				}
			} catch (e) {
				if (e.message === 'Network Error') {
					navigate('library')
				}
				if (e.response.status === 403) {
					setError('The token has expired or the token is invalid. Log in again.')
					localStorage.removeItem('token')
				}
				if (e.response.status !== 403) {
					setError('Something went wrong. Try to login again')
				}

			}
		}
		setLoadingOverlay(false)
	}

	useEffect(() => {
		//navigate('library')
		checkToken()
	}, [])

	const handleLogin = async (pNumber) => {
		const data = await LoginService.login(`+${pNumber}`)
		if (data) {
			setPress(true)
			setTimeout(() => {
				setResend(true)
			}, 15000);
		}
		setIsLoading(false)
	}

	const sendPhone = async () => {
		/*
		//*dev
		//setPress(true)
		setError('')
		setPhone(`+${phoneNum}`)
		//setPress(true)
		setTimeout(() => {
			setResend(true)
		}, 2000);
		*/



		//*prod
		if (!countryPhone || countryPhone.length < 12) {
			setError('The number is not valid')
			return
		} else {
			setError('')
			setIsLoading(true)
			try {
				handleLogin(countryPhone)
			} catch (e) {
				setError(e.message)
				setPress(false)
				setIsLoading(false)
			}

		}

	}

	const sendCode = async () => {
		/*
		//*dev
		//console.log(code);
		//navigate('library')
		*/

		try {
			setIsLoading(true)
			const data = await LoginService.checkSentCode(code, `+${countryPhone}`)
			if (data) {
				//console.log(data);
				setError('')
				localStorage.setItem('token', data.data.accessToken);
				localStorage.setItem('phone', `+${countryPhone}`);
				navigate('library')
			} else {
				setError('Something wrong')
			}
		} catch (e) {
			if (e.response.status === 403) {
				setError('The entered code is invalid')
			} else {
				setError(e.message)
			}
		} finally {
			setIsLoading(false)
		}
	}

	const resendCode = () => {
		setResend(false)

		//*dev 
		//setPress(true)

		//*prod
		setIsLoading(true)
		try {
			handleLogin(countryPhone)
		} catch (e) {
			setError(e.message)
			setPress(false)
		}
		setIsLoading(false)
	}

	const changePhone = () => {
		setPress(false)
		setResend(false)
	}

	const [press, setPress] = useState(false)

	if (loadingOverlay) {
		return <LoadingOverlay />
	}

	return (
		<div className={cl.screen}>
			<div className={cl.images}>
				<div className={cl.img360}>
					<img src={img360} alt="Virtual Safety Induction" />
				</div>
				<div className={cl.logo}>
					<img src={logo} alt='Transport for London' />
				</div>
			</div>
			<div className={cl.form}>
				{press
					? <div className={cl.form__line} style={{ marginBottom: 0 }} >
						<h3>Verify your phone number</h3>
						<article>SMS with a verification code has been sent to +{countryPhone[0]}{countryPhone[1]}{countryPhone[2]}********{countryPhone[countryPhone.length - 2]}{countryPhone[countryPhone.length - 1]}</article>
					</div>
					: <div className={`${cl.form__line} ${error && cl.error}`}>
						<h3>Registration</h3>
						<article>Enter your phone number to verify your account</article>
						<PhoneInput
							country={'gb'}
							preferredCountries={['gb', 'ua']}
							regions={'europe'}
							searchPlaceholder={'search'}
							autocompleteSearch={true}
							inputProps={{
								name: 'phone',
								required: true,
								autoFocus: true
							}}
							placeholder={'+44 9999 99999'}
							isValid={Boolean(!error)}
							value={countryPhone}
							onChange={phone => setCountryPhone(phone)}
						/>
						<div className={cl.policy}>
							<Checkbox checked={checkbox} setChecked={setCheckbox} id='Policy' />
							<label htmlFor='Policy'>I agree to the <a target='_blank' href='policy'>Privacy Policy</a></label>
						</div>

					</div>
				}
				<CSSTransition
					in={press}
					timeout={2000}
					classNames="loginCode"
					unmountOnExit
				>
					<ReactCodeInput type='tel' fields={6} name={''} inputMode={'numeric'} onChange={e => setCode(e)} />
				</CSSTransition>

				{error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}

				{isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><SpinnerWithoutPC /></div>}
				<CSSTransition
					in={resend}
					timeout={2000}
					classNames="loginResend"
					unmountOnExit
				>
					<div className={cl.resend}>
						<p onClick={resendCode}>Haven't received code? <span>Resend</span></p>
						{/*<div className={cl.divider}></div>*/}
						{/*<p>or</p>*/}
						{/*<p onClick={changePhone} style={{color: 'red'}}>Change phone number</p>*/}
					</div>
				</CSSTransition>

				<CSSTransition
					in={press}
					timeout={10000}
					classNames="loginCode"
					unmountOnExit
				>
					<MainButton onClick={sendCode} style={{ width: '145px', textAlign: 'center' }}>Verify</MainButton>
				</CSSTransition>

				{!press && <MainButton style={{ alignSelf: 'flex-end' }} disabled={!checkbox} onClick={sendPhone}>Send code</MainButton>}
			</div>

			{press && <div className='back' onClick={changePhone} >
				<BackIcon />
			</div>}
		</div>
	)
}

export default Login