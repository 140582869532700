export class VideoDB {
	static async addVideo(db, videoName, blob) {
		try {
			const tx = await db.transaction('blobs', 'readwrite');
			const store = await tx.objectStore('blobs');
			await store.put(blob, videoName);
			await tx.done;
		} catch (e) {
			throw Error(e.message)
		}

	}
	static async getVideo(db, videoName) {
		let res
		try {
			res = await db.get('blobs', videoName).then(data => data)
		} catch (e) {
			//res = new Error(e)
		}
		return res
	}
	static async getAllVideos(db) {
		let res
		try {
			res = await db.getAllKeys('blobs').then(data => data.join(','))
		} catch (e) {
			//res = new Error(e)
		}
		return res
	}
}