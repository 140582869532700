export class VideoListDB {
	static async addVideoList(db, list) {
		const tx = db.transaction('videoList', 'readwrite');
		const store = tx.objectStore('videoList');
		await store.put(list, 'array');
		await tx.done;
	}
	static async getVideoList(db) {
		let res
		try{
			res = await db.get('videoList', 'array').then(data => data)
			if(res === undefined) {
				throw new Error('Video list if undefined')
			}
		} catch (e) {
			res = new Error(e)
		}
		return res
	}
}


