import { openDB } from "idb";
import { WatchedListtDB } from "./actionsWatchedListDB";


export async function createWatchedVideosListDB() {
	const db = await openDB('watchedList', 1, {
		upgrade(db, oldVersion, newVersion, transaction) {
			switch (oldVersion) {
				case 0:
					db.createObjectStore('list');
				case 1:
				break;
			}
		}
	});
}

export async function getWatchedVideosListDB(type, data = {}) {
	const db = await openDB('watchedList', 1)
	
	switch (type) {
		case 'addWatchedList':
			WatchedListtDB.addWatchedList(db, data.videoList)
			break;
		case 'getWatchedList':
			let res = await WatchedListtDB.getWatchedList(db)
			//console.log(res);
			return res	
		default:
			break;
	}
}