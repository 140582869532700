/* eslint-disable default-case */
import { openDB } from "idb";
import { VideoListDB } from "./actionsVideoListDB";


export async function createVideoListDB() {
	// Using https://github.com/jakearchibald/idb
	const db = await openDB('list', 1, {
		upgrade(db, oldVersion, newVersion, transaction) {
			switch (oldVersion) {
				case 0:
					db.createObjectStore('videoList');
				case 1:
					getVideoListDB('addVideoList', { videoList: [] })
				break;
			}
		}
	});
}

export async function getVideoListDB(type, data = {}) {
	const db = await openDB('list', 1)

	switch (type) {
		case 'addVideoList':
			VideoListDB.addVideoList(db, data.videoList)
			break;
		case 'getVideoList':
			let res = await VideoListDB.getVideoList(db)
			//console.log(res);
			return res
		default:
			break;
	}
}