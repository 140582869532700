import React from 'react'

import cl from './ProgressBar.module.css'

function ProgressBar({percent = 0, ...props}) {
  return (
	 <div className={cl.bar}>
		 <div className={cl.progress} style={{width: `${percent}%`}}></div>
		 {percent !== 0 && percent !== null ? <p>{percent}%</p> : <p>0%</p>}
	 </div>
  )
}

export default ProgressBar