import arrow1 from '../assets/modules/arrow1.svg'
import arrow2 from '../assets/modules/arrow2.svg'
import arrow3 from '../assets/modules/arrow3.svg'
import arrow4 from '../assets/modules/arrow4.svg'
import arrow5 from '../assets/modules/arrow5.svg'


import bg1 from '../assets/modules/module1.jpg'
import bg2 from '../assets/modules/module2.jpg'
import bg3 from '../assets/modules/module3.jpg'
import bg4 from '../assets/modules/module4.jpg'
import bg5 from '../assets/modules/module5.jpg'
import bg1_1 from '../assets/modules/module1-1.jpg'
import bg2_1 from '../assets/modules/module2-1.jpg'
import bg3_1 from '../assets/modules/module3-1.jpg'
import bg4_1 from '../assets/modules/module4-1.jpg'
import bg5_1 from '../assets/modules/module5-1.jpg'

class ModuleData {
	constructor(id, title, subt, topics, duration, descrTitle, descr, img, imgIn, arrow, textColor, videoName) {
		this.id = id;
		this.title = title;
		this.subt = subt;
		this.topics = topics;
		this.duration = duration;
		this.descrTitle = descrTitle;
		this.descr = descr;
		this.img = img;
		this.imgIn = imgIn;
		this.arrow = arrow;
		this.textColor = textColor;
		this.videoName = videoName;
	}
}
const DATA = [
	new ModuleData(1, 'Module 1 /', 'Getting ready', '1 topic', '4 min', 'Module description', 'In this module you will attempt an induction meeting and learn how to get prepared for your first visit to a Transport for London worksite. Meeting covers the safety basics, PPE and how to get around the worksite safely.', bg1, bg1_1, arrow1, 'primary', 'module1' ),
	new ModuleData(2, 'Module 2 /', 'Are you ready?', '6 topics', '11 min', 'Module description', 'It is time to visit our first worksite. We are heading to the London Underground to learn more about safe commuting and accessing to site, emergency arrangements, personal protection and HSE requirements, and first aid procedures.', bg2, bg2_1, arrow2, 'white', 'module2'),
	new ModuleData(3, 'Module 3 /', 'On site hazards', '4 topics', '8 min', 'Module description', 'Join us at different Transport for London worksites and locations to learn more about specific hazards and their control, including identification, risk assessment and inspections, to keep your workplace healthy and safe. The module covers Depots safety, excavation and trenches, people plant interface, and working at heights.', bg3, bg3_1, arrow3, 'primary', 'module3'),
	new ModuleData(4, 'Module 4 /', 'Clear communication', '7 topics', '12 min', 'Module description', 'A professional standard of communication is integral to the safe and reliable operation of the Transport for London infrastructure.In this module we will learn how to use the ABC-P protocol, how to structure your message properly, what phonetic alphabet is for and why it is important to report hazards.', bg4, bg4_1, arrow4, 'white', 'module4'),
	new ModuleData(5, 'Module 5 /', 'Mental health', '1 topic', '4 min', 'Module description', 'What is mental health and how it impacts on your day to day life? Why is raising mental health awareness in the workplace important? Learn more about different ways to maintain good mental health by following the advices in this module.', bg5, bg5_1, arrow5, 'primary', 'module5')
]

export default DATA