import classNames from 'classnames'
import React from 'react'


import cl from './DownloadButton.module.css'

function DownloadButton({ children, success = false, ...props }) {
	let style = cl.download
	if(success) {
		style = classNames(cl.download, cl.success)	
	}
	return (
		<button className={style} {...props}>
			{children}
		</button>
	)
}

export default DownloadButton